<template>
  <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
    <aside class="sidebar">
      <div class="bg-layer">
        <div
          class="image-layer"
          style="background-image: url(images/background/sidebar-bg-image.jpg)"
        ></div>
      </div>

      <div class="sidebar-widget cat-widget">
        <div class="widget-content">
          <div class="sidebar-title">
            <h4>Hızlı Menü</h4>
          </div>

          <ul class="cat-links clearfix">
            <li>
              <router-link to="/ozgecmis">Başkanımız</router-link>
            </li>

            <li>
              <router-link to="/haberler">Haberler</router-link>
            </li>

            <li>
              <router-link to="/duyuruilanlar">Duyurular</router-link>
            </li>

            <li>
              <router-link to="/galeri">Galeri</router-link>
            </li>

            <li>
              <router-link to="/vefat">Vefat Edenler</router-link>
            </li>

            <li>
              <router-link to="/belgeler">Belge Örnekleri</router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="sidebar-widget recent-posts">
        <div class="widget-inner">
          <div class="sidebar-title">
            <h4>Son Haberler</h4>
          </div>

          <div class="recent-posts-box">
            <div v-for="(haber, index) in Haberler" :key="index" class="post">
              <div class="inner">
                <figure class="post-thumb">
                  <img :src="haber.kapak" alt />
                  <a @click="detayagit(haber.haberId)" class="overlink">
                    <span class="icon flaticon-zoom-in"></span>
                  </a>
                </figure>

                <div class="post-date">{{ haber.haberTarihi }}</div>

                <h5 class="title">
                  <a @click="detayagit(haber.haberId)">{{ haber.haberAdi }}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      Haberler: [],
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Haberler");
    const haberr = this.$store.getters.getHaberler;
    var haber2 = [];
    haber2 = Object.values(haberr);
    haber2.sort(function (b, a) {
      return a.haberId - b.haberId;
    });
    var sayi = 0;
    for (var val of haber2) {
      sayi++;
      if (sayi < 4) this.Haberler.push(val);
    }
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
  },
};
</script>
export const getBelgenel = (state) => state.BelediyeGenelBilgiler;
export const getBirimler = (state) => state.Birimler;
export const getUyeler = (state) => state.Uyeler;
export const getMuhtarlar = (state) => state.Muhtarlar;
export const getEskiBaskanlar = (state) => state.EskiBaskanlar;
export const getEncumenlar = (state) => state.Encumenlar;
export const getKararlar = (state) => state.Kararlar;
export const getIhale = (state) => state.Ihale;
export const getCralanlar = (state) => state.Cralanlar;
export const getRaporlar = (state) => state.Raporlar;
export const getRaporlar2 = (state) => state.Raporlar2;
export const getProjeler = (state) => state.Projeler;
export const getTadilatlar = (state) => state.Tadilatlar;
export const getHaberler = (state) => state.Haberler;
export const getBaskan = (state) => state.BaskanBilgileri;
export const getIlce = (state) => state.IlceBilgiler;
export const getSehitler = (state) => state.Sehitler;
export const getYerler = (state) => state.Yerler;
export const getVefatlar = (state) => state.Vefatlar;
export const getEvlenenler = (state) => state.Evlenenler;
export const getBelgeler = (state) => state.Belgeler;
export const getDuyuruIlanlar = (state) => state.DuyuruIlanlar;
export const getMenuler = (state) => state.Menuler;
export const getSlides = (state) => state.Slides;
export const getDomainId = (state) => state.domainId;
export const getSuanalizler = (state) => state.Suanalizler;
export const getMevzuatlar = (state) => state.Mevzuatlar;



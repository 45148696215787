<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BirimBilgisi.kapak" alt />
                    </figure>
                  </div>

                  <h2>{{ BirimBilgisi.birimadi }}</h2>

                  <p
                    v-html="BirimBilgisi.birimaciklamasi"
                    style="white-space: pre-line"
                  ></p>

                  <div>
                    <h3>İçerikler</h3>
                  </div>
                  <div v-if="BirimBilgisi.Dosyalar.length > 0">
                    <div
                      v-for="(resim, index) in BirimBilgisi.Dosyalar"
                      :key="index"
                      class="
                        gallery-block
                        mix
                        all
                        tour
                        industry
                        col-lg-4 col-md-4 col-sm-12
                      "
                      style="display: inline-block"
                    >
                      <a :href="resim.dosyaurl">İçeriği İndir</a>
                    </div>
                  </div>

                  <hr />

                  <div class="mixit-gallery filter-gallery">
                    <div
                      class="filter-gallery-one row clearfix"
                      id="MixItUp7C2D6E"
                    >
                      <div
                        v-for="(resim, index) in BirimBilgisi.Dosyalar"
                        :key="index"
                        class="
                          gallery-block
                          mix
                          all
                          tour
                          industry
                          col-lg-4 col-md-4 col-sm-12
                        "
                        style="display: inline-block"
                      >
                        <div
                          class="inner-box wow fadeInUp animated"
                          data-wow-delay="0ms"
                          data-wow-duration="1500ms"
                          style="
                            visibility: visible;
                            animation-duration: 1500ms;
                            animation-delay: 0ms;
                            animation-name: fadeInUp;
                          "
                        >
                          <div class="image-box">
                            <figure class="image">
                              <img :src="resim.dosyaurl" alt />
                            </figure>

                            <div class="zoom-btn">
                              <a
                                class="lightbox-image zoom-link"
                                :href="resim.dosyaurl"
                                data-fancybox="gallery"
                              >
                                <span class="icon flaticon-zoom-in"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Sosyal />
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
import Sosyal from "@/views/Sosyalbanner";
export default {
  name: "Birimdetay",
  data() {
    return {
      BirimBilgisi: {},
      loadkont: false,
    };
  },
  components: {
    Sidebar,
    Sosyal,
  },
  async created() {
    const ID = this.$route.params.birimId;
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    const birimmap = this.$store.getters.getBirimler;
    this.BirimBilgisi = birimmap[ID];
    this.loadkont = true;

    //console.log(this.BirimBilgisi)
  },
};
</script>
<template>
  <div>
    <header class="header">
      <div class="container">
        <div class="row">
          <div class="col textarea">
            <div class="air">
              Hava Durumu
              <svg
                id="Capa_1"
                enable-background="new 0 0 512.003 512.003"
                height="512"
                viewBox="0 0 512.003 512.003"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="m409.647 325.827c-.861-13.143-3.523-25.8-7.728-37.737 36.44-24.89 53.145-70.314 40.796-113.438-7.578-26.483-25.017-48.428-49.103-61.793-24.083-13.364-51.929-16.549-78.411-8.97-41.813 11.965-71.438 48.983-74.495 91.74-17.478 3.373-34.205 9.99-49.337 19.649-19.586 12.503-35.761 29.533-47.198 49.604-11.255-3.827-22.999-5.762-35.028-5.762-60.183 0-109.143 48.96-109.143 109.14s48.96 109.14 109.141 109.14h193.414c5.522 0 10-4.477 10-10s-4.478-10-10-10h-193.414c-49.153 0-89.141-39.988-89.141-89.14s39.988-89.141 89.141-89.141c12.384 0 24.396 2.509 35.701 7.458 22.522 9.874 40.132 28.815 48.315 51.966 1.451 4.105 5.309 6.67 9.428 6.67 1.106 0 2.231-.185 3.332-.575 5.207-1.84 7.937-7.554 6.096-12.761-8.815-24.941-26.566-45.884-49.481-58.789 21.936-37.282 61.407-60.064 105.233-60.064 64.249 0 117.074 49.846 121.837 112.896-10.999 1.5-21.536 5.377-30.92 11.446-4.638 2.999-5.967 9.19-2.968 13.828 1.914 2.959 5.127 4.571 8.407 4.571 1.859 0 3.742-.519 5.42-1.604 9.055-5.855 19.57-8.95 30.411-8.95 30.931 0 56.094 25.164 56.094 56.094s-25.162 56.095-56.093 56.095h-17.398c-5.522 0-10 4.477-10 10s4.478 10 10 10h17.398c41.958 0 76.094-34.136 76.094-76.094 0-38.675-29.002-70.703-66.4-75.479zm-141.881-132.801c-2.253 0-4.498.073-6.739.178 3.9-32.812 27.232-60.803 59.675-70.087 21.345-6.109 43.79-3.541 63.205 7.23 19.414 10.773 33.471 28.461 39.579 49.809 9.594 33.504-2.56 68.725-29.741 89.19-23.795-45.327-71.332-76.32-125.979-76.32z"
                  />
                  <circle cx="342.555" cy="467.4" r="10" />
                  <path
                    d="m297.067 76.886c1.257 4.39 5.26 7.25 9.609 7.25.911 0 1.838-.125 2.757-.389 5.31-1.52 8.382-7.057 6.862-12.366l-8.453-29.528c-1.521-5.31-7.061-8.382-12.366-6.861-5.31 1.52-8.382 7.057-6.862 12.366z"
                  />
                  <path
                    d="m194.639 131.818 26.856 14.902c1.537.853 3.202 1.258 4.843 1.258 3.516 0 6.927-1.858 8.753-5.15 2.68-4.829.937-10.916-3.892-13.596l-26.856-14.902c-4.83-2.68-10.917-.936-13.596 3.892-2.68 4.829-.937 10.916 3.892 13.596z"
                  />
                  <path
                    d="m492.52 274.233-26.856-14.902c-4.828-2.68-10.916-.938-13.596 3.892-2.68 4.829-.938 10.917 3.893 13.596l26.856 14.902c1.537.853 3.201 1.258 4.843 1.258 3.516 0 6.926-1.858 8.753-5.15 2.679-4.829.937-10.916-3.893-13.596z"
                  />
                  <path
                    d="m511.614 154.922c-1.52-5.311-7.062-8.382-12.366-6.862l-29.528 8.453c-5.31 1.52-8.382 7.056-6.862 12.366 1.257 4.391 5.26 7.251 9.609 7.251.911 0 1.838-.126 2.757-.389l29.528-8.453c5.309-1.52 8.382-7.056 6.862-12.366z"
                  />
                  <path
                    d="m403.777 94.538c1.537.853 3.201 1.258 4.843 1.258 3.516 0 6.926-1.858 8.753-5.15l14.902-26.857c2.68-4.83.937-10.917-3.893-13.596-4.828-2.68-10.915-.937-13.596 3.892l-14.902 26.857c-2.679 4.829-.936 10.916 3.893 13.596z"
                  />
                </g>
              </svg>
              {{ this.weather }}°
            </div>
          </div>
          <div class="col logo">
            <router-link to="/"
              ><img :src="Belediyebilgiler.kapak" alt=""
            /></router-link>
            <div class="show-header">
              <div class="tool">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                  <div class="container-fluid">
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div
                      class="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                          <router-link class="nav-link" to="/">
                            ANA SAYFA
                          </router-link>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="ozgecmis"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            BAŞKANIMIZ
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <router-link class="dropdown-item" to="/ozgecmis"
                                >Başkanın Özgeçmişi</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/mesaj"
                                >Başkan Mesajları</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/EskiBaskanlar"
                                >Eski Başkanlar</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/baskangaleri"
                                >Başkan Fotoğrafları</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ Belediyebilgiler.belediyeadi }}
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <!-- <li>
                              <router-link class="dropdown-item" to="/ihaleilan"
                                >İhale İlanları</router-link
                              >
                            </li> -->
                            <li>
                              <router-link class="dropdown-item" to="/tarihce"
                                >Tarihçe</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/kentrehberi"
                                >Turistik Yerler</router-link
                              >
                            </li>
                            <li v-for="(menu, index) in Menulist" :key="index">
                              <router-link
                                class="dropdown-item"
                                :to="`/menuler/${menu.menuId}`"
                                >{{ menu.menuAdi }}</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            KURUMSAL
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <router-link class="dropdown-item" to="/birimler"
                                >Birimler</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/meclisuyeleri"
                                >Meclis Üyeleri</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/encumenlarimiz"
                                >Belediye Encümeni</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/etikkomisyonu"
                                >Etik Komisyonu</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/muhtarlar"
                                >Muhtarlarımız</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/kvkk"
                                >KVKK</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            DÖKÜMANLAR
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <router-link class="dropdown-item" to="/kararlar">
                                Meclis Kararları</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/belgeler"
                                >Belge Örnekleri</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/raporlar"
                                >Faaliyet Raporları</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/denetim"
                                >Denetim Raporları</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/mevzuatlar"
                                >Yönetmelikler</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            GÜNCEL
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <router-link class="dropdown-item" to="/projeler">
                                Projeler
                              </router-link>
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/duyuruilanlar"
                              >
                                Duyurular
                              </router-link>
                            </li>
                            <!-- <li>
                              <router-link class="dropdown-item" to="/icrailan">
                                İcra İlanları
                              </router-link>
                            </li> -->
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/tadilatlar"
                                >İmar Tadilatları</router-link
                              >
                            </li>

                            <!-- <li>
                              <router-link class="dropdown-item" to="/denetim"
                                >Denetim Raporları</router-link
                              >
                            </li> -->
                          </ul>
                        </li>

                        <li class="nav-item dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            HİZMETLER
                          </a>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/tadilatlar"
                                >Şehir Kameraları</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item" to="/vefat"
                                >Vefat Edenler</router-link
                              >
                            </li>
                            <li>
                              <router-link
                                class="dropdown-item"
                                to="/evlenenler"
                                >Evlenenler</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <router-link to="/iletisim">İLETİŞİM</router-link>
                        </li>
                        <li class="nav-item" v-if="domAd == 'www.bayat.bel.tr'">
                          <a href="http://178.242.33.92:7077" target="_blank"
                            >e-belediye</a
                          >
                        </li>

                        <div class="socials">
                          <ul>
                            <li>
                              <a href="#">
                                <svg
                                  id="Bold"
                                  enable-background="new 0 0 24 24"
                                  height="512"
                                  viewBox="0 0 24 24"
                                  width="512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <svg
                                  height="682pt"
                                  viewBox="-21 -117 682.66672 682"
                                  width="682pt"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <svg
                                  id="Bold"
                                  enable-background="new 0 0 24 24"
                                  height="512"
                                  viewBox="0 0 24 24"
                                  width="512"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <svg
                                  enable-background="new 0 0 512 512"
                                  version="1.1"
                                  viewBox="0 0 512 512"
                                  xml:space="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </ul>
                    </div>
                  </div>
                </nav>
                <!-- <div id="menutoggle">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul id="menu">
                    <router-link to="/ozgecmis"><li>BAŞKAN</li></router-link>
                    <router-link to="/birimler"><li>KURUMSAL</li></router-link>
                    <router-link to="/projeler"><li>PROJELER</li></router-link>
                    <router-link to="/tarihce"><li>BAYAT</li></router-link>
                    <router-link to="/haberler"><li>HABERLER</li></router-link>
                    <router-link to="/duyuruIlanlar"><li>DUYURULAR</li></router-link>
                    <router-link to="/kararlar"><li>E-BELEDİYE</li></router-link>
                    <router-link to="/belgeler"><li>BELGELER</li></router-link>
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <router-link to="/duyuruIlanlar"><li>HİZMETLER</li></router-link>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <div class="col menuheader">
                      <router-link to="/haberler">Şehir Kameraları</router-link>
                      <router-link to="/haberler">Otobüs Güzergahları</router-link>
                      <router-link to="/vefat">Vefat Edenler</router-link>
                      <router-link to="/evlenenler">Evlenenler</router-link>
                      <router-link to="/haberler">HABERLER</router-link>
                      <router-link to="/haberler">HABERLER</router-link>
                      <router-link to="/haberler">HABERLER</router-link>
                      <router-link to="/haberler">HABERLER</router-link>
                      <router-link to="/haberler">HABERLER</router-link>
                      </div>
                      
                    </ul>
                    <div class="socials">
                      <ul>
                        <li>
                          <a href="#">
                            <svg
                              id="Bold"
                              enable-background="new 0 0 24 24"
                              height="512"
                              viewBox="0 0 24 24"
                              width="512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <svg
                              height="682pt"
                              viewBox="-21 -117 682.66672 682"
                              width="682pt"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <svg
                              id="Bold"
                              enable-background="new 0 0 24 24"
                              height="512"
                              viewBox="0 0 24 24"
                              width="512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <svg
                              enable-background="new 0 0 512 512"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xml:space="preserve"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col social">
            <ul>
              <li>
                <a target="_blank" :href="Belediyebilgiler.twit">
                  <svg
                    id="Bold"
                    enable-background="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a target="_blank" :href="Belediyebilgiler.face">
                  <svg
                    id="Bold"
                    enable-background="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a target="_blank" :href="Belediyebilgiler.insta">
                  <svg
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <header class="anotherheader">
      <div class="container-fluid">
        <div class="row">
          <ul>
            <li><router-link to="/">ANA SAYFA</router-link></li>
            <!-- <router-link to="/tarihce"></router-link> -->

            <li class="menuBaskan">
              <a href="#" class="menuBaskan">
                BAŞKANIMIZ &nbsp;<i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuBaskan" style="background-color: #2f6267">
                <router-link to="/ozgecmis">Başkanın Özgeçmişi</router-link>
                <router-link to="/mesaj">Başkan Mesajları</router-link>
                <router-link to="/EskiBaskanlar">Eski Başkanlar</router-link>
                <router-link to="/baskangaleri"
                  >Başkan Fotoğrafları</router-link
                >
              </ul>
            </li>
            <li class="menuBelediye">
              <a class="menuBelediye">
                {{ Belediyebilgiler.belediyeadi }} &nbsp;
                <i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuBelediye" style="background-color: #2f6267">
                <router-link class="dropdown-item" to="/tarihce"
                  >Tarihçe</router-link
                >
                <router-link class="dropdown-item" to="/kentrehberi"
                  >Turistik Yerler</router-link
                >
                <router-link
                  v-for="(menu, index) in Menulist"
                  :key="index"
                  class="dropdown-item"
                  :to="`/menuler/${menu.menuId}`"
                  >{{ menu.menuAdi }}</router-link
                >
              </ul>
            </li>
            <!-- <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              BELEDİYE
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              
            </ul> -->

            <li class="menu-kurumsal">
              <a class="menu-kurumsal">
                KURUMSAL &nbsp;<i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuKurumsal" style="background-color: #2f6267">
                <router-link to="/birimler"> Birimler</router-link>
                <router-link to="/meclisuyeleri">Meclis Üyeleri</router-link>
                <router-link to="/encumenlarimiz"
                  >Belediye Encümeni</router-link
                >
                <router-link to="/etikkomisyonu">Etik Komisyonu</router-link>
                <router-link to="/muhtarlar">Muhtarlarımız</router-link>
                <router-link to="/kvkk">KVKK</router-link>
              </ul>
            </li>

            <li class="menu-dokumanlar">
              <a class="menu-dokumanlar">
                DÖKÜMANLAR &nbsp;<i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuDokumanlar" style="background-color: #2f6267">
                <!-- <router-link class="dropdown-item" to="/ihaleilan"
                >İhale İlanları</router-link
              > -->
                <router-link class="dropdown-item" to="/kararlar"
                  >Meclis Kararları</router-link
                >
                <router-link class="dropdown-item" to="/belgeler"
                  >Belge Örnekleri</router-link
                >
                <router-link class="dropdown-item" to="/raporlar"
                  >Faaliyet Raporları</router-link
                >
                <router-link class="dropdown-item" to="/denetim"
                  >Denetim Raporları</router-link
                >
                <router-link class="dropdown-item" to="/mevzuatlar"
                  >Yönetmelikler</router-link
                >
                <router-link class="dropdown-item" to="/suanalizsonuc"
                  >Su Analiz Sonuçları</router-link
                >
                <router-link class="dropdown-item" to="/kloraramasonuc"
                  >Klor Arama Sonuçları</router-link
                >
                <router-link class="dropdown-item" to="/hıfzıssıhhasonuc"
                  >R. S. Hıfzıssıhha Sonuçları</router-link
                >
              </ul>
            </li>

            <li class="menuGuncel">
              <a class="menuGuncel">
                GÜNCEL &nbsp;<i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuGuncel" style="background-color: #2f6267">
                <router-link to="/projeler"> Projeler </router-link>
                <router-link to="/duyuruilanlar">Duyurular</router-link>
                <router-link to="/tadilatlar">İmar Tadilat</router-link>
                <router-link to="/galeri">Galeri</router-link>
              </ul>
            </li>

            <li class="menuHizmetler">
              <a class="menuHizmetler">
                HİZMETLER &nbsp; <i class="fas fa-sort-down"></i>
              </a>
              <ul class="drop-menuHizmetler" style="background-color: #2f6267">
                <a :href="Belediyebilgiler.cityCamera" target="_blank"
                  >Şehir Kameraları</a
                >
                <router-link to="/vefat">Vefat Edenler</router-link>
                <router-link to="/evlenenler">Evlenenler</router-link>
              </ul>
            </li>
            <li><router-link to="/iletisim">İLETİŞİM</router-link></li>
            <i class="fab fa-edge-legacy"></i>
            <li v-if="domAd == 'www.bayat.bel.tr'">
              <a href="http://178.242.33.92:7077" target="_blank"
                ><i class="fab fa-internet-explorer"></i> Belediye</a
              >
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      Menuler: {},
      Menulist: {},
      domAd: "",
      weather: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    //console.log(this.domAd);
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Menuler");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Menuler = this.$store.getters.getMenuler;
    this.Menulist = Object.values(this.Menuler);
    this.Menulist.sort(function (b, a) {
      return a.menuId - b.menuId;
    });
    this.getWeather();
  },
  methods: {
    hoverBaskan() {
      //console.log("sa ben geldim");
    },
    getWeather() {
      //console.log("hava data");
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${this.Belediyebilgiler.sehir}&units=metric&appid=72b6bed996a5d3c810c7794ba06a3a70`
      )
        .then((response) => response.json())
        .then((data) => (this.weather = Math.round(data.main.temp)));
    },
  },
};
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #217c3d;
}
</style>

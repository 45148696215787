<template>
  <div>
    <!-- SLİDER -->
    <div
      id="carouselExampleControlsNoTouching"
      class="carousel slide"
      data-bs-touch="false"
      data-bs-interval="false"
    >
      <div class="carousel-inner">
        <!-- <div
          class="carousel-item "
          v-for="(slide, index) in Slides.Dosyalar"
          :key="index"
          :class="[Slides, { active: index === 0 }]"
        >
          <img
            :src="slide.dosyaurl"
            class="d-block w-100 slide-image"
            alt="..."
          />
        </div> -->
        <div v-if="domAD == 'www.bayat.bel.tr'" style="height: 100%">
          <div class="carousel-item Slides active" style="height: 100%">
            <!-- <img class="slideBackground" :src="slide.dosyaurl"> -->
            <iframe
              class="w-75"
              src="https://www.youtube.com/embed/kjulIz0vqMg?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=kjulIz0vqMg&mute=1"
              style="height: 100%"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="carousel-item"
            v-for="(slide, index) in Slides.Dosyalar"
            :key="index"
          >
            <!-- <img class="slideBackground" :src="slide.dosyaurl"> -->
            <img :src="slide.dosyaurl" class="d-block w-75 slide-image" />
          </div>
        </div>

        <div v-else>
          <div
            class="carousel-item"
            v-for="(slide, index) in Slides.Dosyalar"
            :key="index"
            :class="[Slides, { active: index === 0 }]"
          >
            <!-- <img class="slideBackground" :src="slide.dosyaurl"> -->
            <img :src="slide.dosyaurl" class="d-block w-75 slide-image" />
          </div>
        </div>
      </div>

      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControlsNoTouching"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bg-dark"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControlsNoTouching"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon bg-dark"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- SLİDER -->

    <!-- SLIDER MENU
    <section class="anotherslide mt-5">
      <div class="container-fluid">
        <div class="row">
          <div id="owl-demo" class="row owl-carousel owl-theme">
            <div class="item">
              <router-link to="/"
                ><i class="fas fa-home"></i>ANASAYFA
              </router-link>
            </div>
            <div class="item">
              <router-link to="/duyuruilanlar"
                ><i class="fas fa-bell"></i>DUYURULAR</router-link
              >
            </div>
            <div class="item">
              <router-link to="/haberler"
                ><i class="fas fa-newspaper"></i>HABERLER</router-link
              >
            </div>
         
            <div class="item">
              <router-link to="/galeri"
                ><i class="fas fa-images"></i>GALERİ</router-link
              >
            </div>
          

            <div class="item">
              <router-link to="/projeler"
                ><i class="fas fa-clone"></i>PROJELER</router-link
              >
            </div>
            <div class="item">
              <router-link to="/evlenenler"
                ><i class="fas fa-heart"></i>EVLENENLER</router-link
              >
            </div>
            <div class="item">
              <router-link to="/muhtarlar"
                ><i class="fas fa-user-alt"></i>MUHTARLAR</router-link
              >
            </div>

            
            <div class="item">
              <router-link to="/iletisim"
                ><i class="fas fa-phone"></i>İLETİŞİM</router-link
              >
            </div>
            

            <div class="item">
              <router-link to="/birimler">
                <i class="fas fa-flag"></i>BİRİMLER</router-link
              >
            </div>
            
            <div class="item">
              <router-link to="/kentrehberi"
                ><i class="fas fa-chart-pie"></i>REHBER</router-link
              >
            </div>
            
          </div>
        </div>
      </div>
    </section>
     -->
    <!-- SLIDER MENU -->

    <!-- HABERLER, DUYURULAR, KENT REHBERİ BAŞLIK -->
    <section id="text">
      <div class="container">
        <div class="row">
          <div class="col bulletin mt-5">
            <div class="text">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-dark active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#news"
                    type="button"
                    role="tab"
                    aria-controls="news"
                    aria-selected="true"
                  >
                    HABERLER
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-dark"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#announcements"
                    type="button"
                    role="tab"
                    aria-controls="announcements"
                    aria-selected="false"
                  >
                    DUYURULAR
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link text-dark"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#events"
                    type="button"
                    role="tab"
                    aria-controls="events"
                    aria-selected="false"
                  >
                    KENT REHBERİ
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- HABERLER, DUYURULAR, KENT REHBERİ BAŞLIK-->

    <!-- HABERLER, DUYURULAR, KENT REHBERİ -->
    <section id="banner">
      <div class="container">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="news"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row row-cols-md-3 mt-4">
              <div class="col col-md-3 protocol" style="max-height: 575px">
                <div class="image">
                  <img :src="Baskanbilgisi.kapak" alt="" />
                </div>
                <div class="text">
                  <div class="head">
                    <h3>{{ Baskanbilgisi.baskanadi }}</h3>
                    <p>{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANI</p>
                  </div>
                  <div class="president">
                    <ul>
                      <li>
                        <router-link to="/ozgecmis">
                          Başkanın Özgeçmişi
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/mesaj"> Başkanın Mesajı </router-link>
                      </li>
                      <li>
                        <router-link to="/baskangaleri">
                          Fotoğraf Galerisi
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col col-md-9 big-card">
                <div class="card" style="max-height: 575px">
                  <div
                    class="img"
                    :style="
                      'background-image:' + `url(${Haberler[haberIndex].kapak})`
                    "
                  ></div>
                  <div class="card-img-overlay text-show">
                    <h5 class="card-title">
                      <a
                        id="card-title-a"
                        @click="detayagit(Haberler[haberIndex].haberId)"
                        style="font-size: 32px"
                      >
                        {{ Haberler[haberIndex].haberAdi.slice(0, 31) }}...
                      </a>
                      <br />
                      <br />
                      <!-- <a @click="detayagit(Haberler[haberIndex].haberId)">
                     <p v-html="Haberler[haberIndex].haberAciklamasi.slice(0,130)" style="font-size:18px;"></p>
                    </a> -->
                      <h5 class="date">
                        {{ Haberler[haberIndex].haberTarihi }}
                      </h5>

                      <br />
                    </h5>
                  </div>

                  <div
                    class="eventarea"
                    style="
                      max-height: 50px;
                      display: inline-flex;
                      overflow: hidden;
                    "
                  >
                    <div
                      v-for="(altHaber, index1) in Haberler.slice(0, 21)"
                      :key="index1"
                      class="eventshow"
                    >
                      <!--
                       <div class="image">
                        <a @click="selectedIndex(index1, 'haber')"
                          ><img :src="altHaber.kapak" alt=""
                        /></a>
                      </div>
                       -->

                      <div class="text" @show="active">
                        <a
                          @touchstart="selectedIndex(index1, 'haber')"
                          @mouseenter="selectedIndex(index1, 'haber')"
                        >
                          <h5>
                            <!--  {{ altHaber.haberAdi.slice(0, 15) }}... -->
                            {{
                              altHaber.haberAdi.replace(
                                altHaber.haberAdi,
                                index1
                              )
                            }}
                            <span class="hoverSpan"></span>
                          </h5>
                        </a>
                        <!-- <p v-html="altHaber.haberAciklamasi"></p> -->
                      </div>
                    </div>
                    <button
                      class="nav-link"
                      id="tumunuGorButton"
                      style="
                        float: right;
                        padding: 4px;
                        z-index: 1;
                        background-color: #2f6267;
                        bottom-right-border-radius: 10px;
                      "
                    >
                      <router-link to="/haberler" id="tumunuGorButton">
                        TÜMÜNÜ GÖR
                      </router-link>
                    </button>
                  </div>
                </div>
                <router-link
                  to="/haberler"
                  id="tumunuGorButtonMobil"
                  style="float: right"
                >
                  TÜMÜNÜ GÖR
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade show"
            id="announcements"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="row row-cols-md-2 mt-4">
              <div class="col col-md-3 protocol" style="max-height: 575px">
                <div class="image">
                  <img :src="Baskanbilgisi.kapak" alt="" />
                </div>
                <div class="text">
                  <div class="head">
                    <h3>{{ Baskanbilgisi.baskanadi }}</h3>
                    <p>{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANI</p>
                  </div>
                  <div class="president">
                    <ul>
                      <li>
                        <router-link to="/ozgecmis">
                          Başkanın Özgeçmişi
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/mesaj"> Başkanın Mesajı </router-link>
                      </li>
                      <li>
                        <router-link to="/baskangaleri">
                          Fotoğraf Galerisi
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col col-md-9 big-card">
                <div class="card" style="max-height: 575px">
                  <div
                    class="img"
                    :style="
                      'background-image:' + `url(${Ilanlar[ilanIndex].kapak})`
                    "
                  ></div>
                  <div class="card-img-overlay text-show">
                    <h5 class="card-title">
                      <a
                        @click="duyurudetayagit(Ilanlar[ilanIndex].duyruilanId)"
                      >
                        {{ Ilanlar[ilanIndex].duyruilanAdi.slice(0, 31) }}...
                      </a>
                      <br />
                      <br />
                      <!-- <a @click="duyurudetayagit(Ilanlar[ilanIndex].duyruilanId)">
                      <p v-html="Ilanlar[ilanIndex].duyruilanAciklamasi.slice(0,130)" style="font-size:18px;"></p>
                      </a> -->
                      <h5 class="date">
                        {{ Ilanlar[ilanIndex].duyruilanTarihi }}
                      </h5>
                    </h5>
                    <!-- <p class="card-text" v-html="ilan.duyruilanAciklamasi"></p> -->
                  </div>

                  <div
                    class="eventarea"
                    style="
                      max-height: 50px;
                      display: inline-flex;
                      overflow: hidden;
                    "
                  >
                    <div
                      v-for="(altIlan, index1) in Ilanlar.slice(0, 21)"
                      :key="index1"
                      class="eventshow"
                    >
                      <!-- 
                       <div class="image">
                        <a @click="selectedIndex(index1, 'ilan')">
                          <img :src="altIlan.kapak" alt="" />
                        </a>
                      </div>
                      -->
                      <div class="text">
                        <a
                          @mouseenter="selectedIndex(index1, 'ilan')"
                          @touchstart="selectedIndex(index1, 'ilan')"
                        >
                          <h5>
                            <!--  {{ altIlan.duyruilanAdi.slice(0, 15) }}... -->
                            {{
                              altIlan.duyruilanAdi.replace(
                                altIlan.duyruilanAdi,
                                index1
                              )
                            }}
                            <span class="hoverSpan"></span>
                          </h5>
                        </a>
                        <!-- <p v-html="altIlan.duyruilanAciklamasi"></p> -->
                      </div>
                    </div>
                    <button
                      class="nav-link"
                      id="tumunuGorButton"
                      style="
                        float: right;
                        padding: 4px;
                        z-index: 1;
                        background-color: #2f6267;
                        bottom-right-border-radius: 10px;
                      "
                    >
                      <router-link to="/haberler" id="tumunuGorButton">
                        TÜMÜNÜ GÖR
                      </router-link>
                    </button>
                  </div>
                </div>
                <router-link
                  to="/duyuruilanlar"
                  id="tumunuGorButtonMobil"
                  style="float: right"
                >
                  TÜMÜNÜ GÖR
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade show"
            id="events"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="row row-cols-md-2 mt-4">
              <div class="col col-md-3 protocol" style="max-height: 575px">
                <div class="image">
                  <img :src="Baskanbilgisi.kapak" alt="" />
                </div>
                <div class="text">
                  <div class="head">
                    <h3>{{ Baskanbilgisi.baskanadi }}</h3>
                    <p>{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANI</p>
                  </div>
                  <div class="president">
                    <ul>
                      <li>
                        <router-link to="/ozgecmis">
                          Başkanın Özgeçmişi
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/mesaj"> Başkanın Mesajı </router-link>
                      </li>
                      <li>
                        <router-link to="/baskangaleri">
                          Fotoğraf Galerisi
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col col-md-9 big-card">
                <div class="card" style="max-height: 575px">
                  <div
                    class="img"
                    :style="
                      'background-image:' + `url(${Yerler[yerIndex].kapak})`
                    "
                  ></div>
                  <div class="card-img-overlay text-show">
                    <h5 class="card-title">
                      <a
                        @click="yerdetayagit(Yerler[yerIndex].yerId)"
                        style="font-size: 32px"
                      >
                        {{ Yerler[yerIndex].yerAdi.slice(0, 31) }}...
                      </a>
                      <br />
                      <br />
                      <!-- <a @click="yerdetayagit(Yerler[yerIndex].yerId)">
                       <p v-html="Yerler[yerIndex].yerAciklamasi.slice(0,130)" style="font-size:18px;"></p>
                       </a> -->
                      <h5 class="date">{{ Yerler[yerIndex].yerTarihi }}</h5>
                    </h5>
                    <!-- <p class="card-text" v-html="yer.yerAciklamasi"></p> -->
                  </div>
                  <div
                    class="eventarea"
                    style="
                      max-height: 50px;
                      display: inline-flex;
                      overflow: hidden;
                    "
                  >
                    <div
                      v-for="(altYer, index1) in Yerler.slice(0, 21)"
                      :key="index1"
                      class="eventshow"
                    >
                      <!--
                        <div class="image">
                        <a @click="selectedIndex(index1, 'yer')">
                          <img :src="altYer.kapak" alt="" />
                        </a>
                      </div>
                      -->
                      <div class="text">
                        <a
                          @touchstart="selectedIndex(index1, 'yer')"
                          @mouseenter="selectedIndex(index1, 'yer')"
                        >
                          <h5>
                            <!-- {{ altYer.yerAdi.slice(0, 15) }}... -->
                            {{ altYer.yerAdi.replace(altYer.yerAdi, index1) }}
                            <span class="hoverSpan"></span>
                          </h5>
                        </a>
                        <!-- <p v-html="altYer.yerAciklamasi"></p> -->
                      </div>
                    </div>
                    <button
                      class="nav-link"
                      id="tumunuGorButton"
                      style="
                        float: right;
                        padding: 4px;
                        z-index: 1;
                        background-color: #2f6267;
                        bottom-right-border-radius: 10px;
                      "
                    >
                      <router-link to="/haberler" id="tumunuGorButton">
                        TÜMÜNÜ GÖR
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col col-md-12 sliders">
                <div class="owl-carousel owl-theme owl">
                  <div
                    v-for="(mobilYer, index) in Yerler.slice(1, 2)"
                    :key="index"
                    class="item"
                  >
                    <div class="eventshow">
                      <div class="image">
                        <img :src="mobilYer.kapak" alt="" />
                      </div>
                      <div class="text">
                        <h5>{{ mobilYer.yerAdi.slice(0, 18) }}</h5>
                        <!-- <p v-html="mobilYer.yerAciklamasi"></p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <router-link
              to="/kentrehberi"
              id="tumunuGorButtonMobil"
              style="float: right"
            >
              TÜMÜNÜ GÖR
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- HABERLER, DUYURULAR, KENT REHBERİ -->

    <!-- PROJELER, İHALELER, AÇIKLAMALAR -->
    <section id="secondtext">
      <div class="container">
        <div class="row row-cols-md-2">
          <div class="col col-md-12 bulletin mt-5">
            <div class="text">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#projects"
                    type="button"
                    role="tab"
                    aria-controls="projects"
                    aria-selected="false"
                  >
                    <a
                      ><i class="fas fa-project-diagram" id="tablistIcons"></i>
                      <p class="tablistBaslik">PROJELER</p></a
                    >
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tenders"
                    type="button"
                    role="tab"
                    aria-controls="tenders"
                    aria-selected="false"
                  >
                    <a
                      ><i class="fas fa-draw-polygon" id="tablistIcons"></i>
                      <p class="tablistBaslik">İHALELER</p></a
                    >
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="second-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#descriptions"
                    type="button"
                    role="tab"
                    aria-controls="descriptions"
                    aria-selected="false"
                  >
                    <a
                      ><i class="fas fa-bullhorn" id="tablistIcons"></i>
                      <p class="tablistBaslik">RESMİ İLANLAR</p></a
                    >
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="seconds-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#deads"
                    type="button"
                    role="tab"
                    aria-controls="deads"
                    aria-selected="false"
                  >
                    <a
                      ><i class="fas fa-pray" id="tablistIcons"></i>
                      <p class="tablistBaslik">VEFAT EDENLER</p></a
                    >
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="aftersecond-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#renovations"
                    type="button"
                    role="tab"
                    aria-controls="renovations"
                    aria-selected="false"
                  >
                    <a
                      ><i class="far fa-building" id="tablistIcons"></i>
                      <p class="tablistBaslik">İMAR TADİLAT</p></a
                    >
                  </button>
                </li>
              </ul>
              <div class="tab-content m-4" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="projects"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  <div class="arena">
                    <div class="contackarea col-md-8">
                      <div
                        v-for="(proje, index) in Projeler.slice(0, 6)"
                        :key="index"
                        class="texts"
                      >
                        <div class="row head">
                          <h3 class="col-10 pr-2">
                            {{ proje.projeAdi.slice(0, 30) }}...
                          </h3>
                          <a
                            class="col-2"
                            v-if="proje.Dosyalar.length > 0"
                            @click="projedetayagit(proje.projeId)"
                          >
                            detay için
                          </a>
                        </div>
                        <p>{{ proje.projeTarihi }}</p>
                      </div>
                      <div class="texts">
                        <div class="row head d-flex justify-content-center">
                          <router-link class="col-2" to="/projeler">
                            Tümünü Gör
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(proje2, index) in Projeler.slice(0, 1)"
                      :key="index"
                      class="video col-md-4"
                    >
                      <img :src="proje2.kapak" width="100%" alt="" />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade show"
                  id="tenders"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  <div class="arena">
                    <div class="contackarea col-md-8">
                      <div
                        v-for="(ihale, index) in Ihale.slice(0, 6)"
                        :key="index"
                        class="texts"
                      >
                        <div class="row head">
                          <h3 class="col-10 pr-2">
                            {{ ihale.ihaleAdi }}
                          </h3>
                          <a
                            class="col-2"
                            v-if="ihale.Dosyalar.length > 0"
                            @click="ihaledetayagit(ihale.ihaleId)"
                          >
                            detay için
                          </a>
                        </div>
                        <p>{{ ihale.ihaleTarihi }}</p>
                      </div>
                      <div class="texts">
                        <div class="row head d-flex justify-content-center">
                          <router-link class="col-2" to="/ihaleler">
                            Tümünü Gör
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(ihale2, index) in Ihale.slice(0, 1)"
                      :key="index"
                      class="video col-md-4"
                    >
                      <img :src="ihale2.kapak" width="100%" alt="" />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade show"
                  id="descriptions"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  <div class="arena">
                    <div class="contackarea col-md-8">
                      <div
                        v-for="(ilan, index) in Ilanlar.slice(0, 6)"
                        :key="index"
                        class="texts"
                      >
                        <div class="row head">
                          <h3 class="col-10 pr-2">
                            {{ ilan.duyruilanAdi.slice(0, 30) }}...
                          </h3>
                          <a
                            class="col-2"
                            @click="duyurudetayagit(ilan.duyruilanId)"
                          >
                            detay için
                          </a>
                        </div>
                        <p>{{ ilan.duyruilanTarihi }}</p>
                      </div>
                      <div class="texts">
                        <div class="row head d-flex justify-content-center">
                          <router-link class="col-2" to="/duyuruilanlar">
                            Tümünü Gör
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(ilan2, index) in Ilanlar.slice(0, 1)"
                      :key="index"
                      class="video col-md-4"
                    >
                      <img :src="ilan2.kapak" width="100%" alt="" />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade show"
                  id="deads"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  <div class="arena">
                    <div class="contackarea col-md-8">
                      <div
                        v-for="(vefat, index) in Vefatedenler.slice(0, 6)"
                        :key="index"
                        class="texts"
                      >
                        <div class="row head">
                          <h3 class="col-10 pr-2">
                            {{ vefat.vefatAdi.slice(0, 30) }}...
                          </h3>
                          <router-link class="col-2" to="/vefat">
                            detay için
                          </router-link>
                        </div>
                        <p>{{ vefat.vefatTarih }}</p>
                      </div>
                      <div class="texts">
                        <div class="row head d-flex justify-content-center">
                          <router-link class="col-2" to="/vefat">
                            Tümünü Gör
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(vefat2, index) in Vefatedenler.slice(0, 1)"
                      :key="index"
                      class="video col-md-4"
                    >
                      <img :src="vefat2.kapak" width="100%" alt="" />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade show"
                  id="renovations"
                  role="tabpanel"
                  aria-labelledby="second-tab"
                >
                  <div class="arena">
                    <div class="contackarea col-md-8">
                      <div
                        v-for="(tadilat, index) in Tadilatlar.slice(0, 6)"
                        :key="index"
                        class="texts"
                      >
                        <div class="row head">
                          <h3 class="col-10 pr-2">
                            {{ tadilat.tadilatAdi.slice(0, 30) }}...
                          </h3>
                          <a
                            class="col-2"
                            v-if="tadilat.Dosyalar.length > 0"
                            @click="tadilatdetayagit(tadilat.tadilatId)"
                          >
                            detay için
                          </a>
                        </div>
                        <p>{{ tadilat.Tarihi }}</p>
                      </div>
                      <div class="texts">
                        <div class="row head d-flex justify-content-center">
                          <router-link class="col-2" to="/tadilatlar">
                            Tümünü Gör
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div
                      v-for="(tadilat2, index) in Tadilatlar.slice(0, 1)"
                      :key="index"
                      class="video col-md-4"
                    >
                      <img :src="tadilat2.kapak" width="100%" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- PROJELER, İHALELER, AÇIKLAMALAR -->

    <!--  VİDEO HABERLER -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div id="news-slider" class="owl-carousel">
            <div
              class="post-slide"
              v-for="(haber, index) in Haberler.slice(0, 6)"
              :key="index"
              :style="'width:100'"
            >
              <div
                v-if="
                  haber.youtubeLink !== null &&
                  haber.youtubeLink !== '' &&
                  haber.youtubeLink !== '-' &&
                  haber.youtubeLink !== ' ' &&
                  haber.youtubeLink !== undefined
                "
              >
                <div class="post-img">
                  <iframe
                    :src="
                      'https://www.youtube.com/embed/' + `${haber.youtubeLink}`
                    "
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="post-content">
                  <h3 class="post-title">
                    <a href="#">{{ haber.haberAdi.slice(0, 35) }}...</a>
                  </h3>
                  <!-- <p class="post-description" v-html="haber.haberAciklamasi"></p> -->
                  <span class="post-date"
                    ><i class="fa fa-clock-o"></i>{{ haber.haberTarihi }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- VİDEO HABERLER -->

    <!-- FOOTER SLİDER -->
    <!-- <section class="footerslide mt-5">
      <div class="container-fluid">
        <div class="row">
          <div id="owl-demo2" class="owl-carousels owl-theme">
            <div class="item">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Emblem_of_the_Presidency_of_Turkey.svg/1200px-Emblem_of_the_Presidency_of_Turkey.svg.png"
                alt=""
              />
            </div>
            <div class="item">
              <img
                src="https://www.icisleri.gov.tr/kurumlar/icisleri.gov.tr/icisleri/Kurumsal/içişleri%20bakanlığı%20logo_01.png"
                alt=""
              />
            </div>
            <div class="item">
              <img
                src="https://i.pinimg.com/originals/14/5a/b4/145ab48cd0b204876477207c2a65d642.png"
                alt=""
              />
            </div>
            <div class="item">
              <img
                src="../assets/qj9dd3j.png"
                alt=""
              />
            </div>
            <div class="item">
              <img
                src="https://upload.wikimedia.org/wikipedia/tr/a/ad/Kulturveturizmbakanligi_logo.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- FOOTER SLİDER -->
    <riza-metni />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      haberIndex: 0,
      ilanIndex: 0,
      yerIndex: 0,
      urll: "",
      Haberler: [],
      Slides: [],
      Ilanlar: [],
      IlanlarList: {},
      Ihale: [],
      IhaleList: {},
      Yerler: [],
      Vefatedenler: [],
      VefatedenlerList: {},
      Baskanbilgisi: {},
      Projeler: [],
      ProjelerList: {},
      Tadilatlar: [],
      TadilatlarList: {},
      Cralanlar: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
      active: false,
    };
  },
  components: {},
  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;
    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    //console.log(this.Ihale);
    await this.yerlercek();
    await this.projelercek();
    await this.tadilatlarcek();
    //console.log(this.Tadilatlar);
    await this.createdcek();
    this.scriptekleslide();
    await this.Cralanlarcek();
    //console.log(this.Haberler);
    //console.log("buradan sonra id gleecek");
    //console.log(
    this.YouTubeGetID("https://www.youtube.com/watch?v=hWv2puPpN8g");
    // this.hoverHaber();
  },
  methods: {
    YouTubeGetID(videoId) {
      var video_id = videoId;
      video_id = video_id.split("v=")[1];
      var ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      return video_id;
    },

    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    ihaledetayagit(ID) {
      this.$router.push("/ihaledetay/" + ID);
    },
    tadilatdetayagit(ID) {
      this.$router.push("/tadilatdetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    cralanlardetayagit(ID) {
      this.$roter.push("/cralandetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    vefatlardetaygit(ID) {
      this.$router.push("/vefatdetay/" + ID);
    },

    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      /*    console.log("burası neresi")
      this.Haberler.forEach(el => {
        var videoId = this.YouTubeGetID(el.youtubeLink)
        console.log(videoId)
      }); */
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    async belediyecek() {},
    async scriptekleslide() {
      // SCRİPT
      // let customscript = await document.createElement("script");
      // customscript.setAttribute("src", "js/custom-script.js");
      // document.head.appendChild(customscript);
      // // SCRİPT
      // let customscript2 = await document.createElement("script");
      // customscript2.setAttribute("src", "js/main.js");
      // document.head.appendChild(customscript2);
      // SCRİPT
      // let customscript3 = await document.createElement("script");
      // customscript3.setAttribute("src", "js/plugins.js");
      // document.head.appendChild(customscript3);
      // // SCRİPT
      // let customscript4 = await document.createElement("script");
      // customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      // document.head.appendChild(customscript4);
      // SCRİPT
      let customscript5 = await document.createElement("script");
      customscript5.setAttribute("src", "js/script.js");
      document.head.appendChild(customscript5);
      let customscript6 = await document.createElement("script");
      customscript6.setAttribute(
        "src",
        "assets/owl-carousel/owl.carousel.min.js"
      );
      document.head.appendChild(customscript6);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      this.IlanlarList = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(this.IlanlarList);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Ihale");
      this.IhaleList = this.$store.getters.getIhale;
      this.Ihale = Object.values(this.IhaleList);
      this.Ihale.sort(function (b, a) {
        return a.ihaleId - b.ihaleId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      this.ProjelerList = this.$store.getters.getProjeler;
      this.Projeler = Object.values(this.ProjelerList);
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
    },
    async tadilatlarcek() {
      await this.$store.dispatch("vericekme", "Tadilatlar");
      this.TadilatlarList = this.$store.getters.getTadilatlar;
      this.Tadilatlar = Object.values(this.TadilatlarList);
      this.Tadilatlar.sort(function (b, a) {
        return a.tadilatId - b.tadilatId;
      });
    },
    async createdcek() {
      const domainAd = window.location.hostname;
      await this.$store.dispatch("domainbelirle", domainAd);
      await this.$store.dispatch("vericekme", "Vefatlar");
      this.VefatedenlerList = this.$store.getters.getVefatlar;
      this.Vefatedenler = Object.values(this.VefatedenlerList);
      this.Vefatedenler.sort(function (b, a) {
        return a.vefatId - b.vefatId;
      });
      this.loadkont = true;
    },
    async Cralanlarcek() {
      await this.$store.dispatch("vericekme", "Cralanlar");
      const Cralanlar1 = this.$store.getters.getCralanlar;
      this.Cralanlar = Object.values(Cralanlar1);
      this.Cralanlar.sort(function (b, a) {
        return a.cralanlarId - b.cralanlarId;
      });
    },

    selectedIndex: function (index, arg) {
      switch (arg) {
        case "haber":
          this.haberIndex = index;
          break;
        case "ilan":
          this.ilanIndex = index;
          break;
        case "yer":
          this.yerIndex = index;
          break;

        default:
          break;
      }
    },
  },
  computed: {
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },

    selectedNews(index) {
      console.log(index);
      return index;
    },
  },
  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}

.carousel-inner .carousel-item .slide-image {
  height: 60vh !important;
}
.carousel-inner {
  height: 60vh !important;
}
.carousel-item {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -o-center;
  text-align: -ms-center;
}

.owl-carousel.owl-drag .owl-item {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -o-center;
  text-align: -ms-center;
}

.slideBackground {
  width: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
#owl-demo2 .item {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -o-center;
  text-align: -ms-center;
}
#owl-demo2 img {
  height: 100px !important;
  width: auto;
}
</style>

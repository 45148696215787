<template>
  <div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Refik Saydam Hıfzıssıhha Sonuçları</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="events-list">
                <!--Event Block-->

                <div
                  v-for="(suanaliz, index) in HıfzıssıhhaSonuc"
                  :key="index"
                  class="event-block"
                >
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">{{
                            suanaliz.suanalizTarihi.split(" ")[0]
                          }}</span>
                          <span class="month">{{
                            suanaliz.suanalizTarihi.split(" ")[1]
                          }}</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a
                            :href="suanaliz.Dosyalar[0].dosyaurl"
                            target="blank"
                            >Hıfzıssıhha Sonuç</a
                          >
                        </div>

                        <h3>
                          <a
                            :href="suanaliz.Dosyalar[0].dosyaurl"
                            target="blank"
                            >{{ suanaliz.suanalizAdi }}</a
                          >
                        </h3>

                        <div class="location">
                          {{ Belediyebilgiler.belediyeadi }} Belediyesi
                        </div>

                        <div class="read-more">
                          <a
                            :href="suanaliz.Dosyalar[0].dosyaurl"
                            target="blank"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--<div style="text-align: center">
                <button class="loadclass">Daha fazla</button>
              </div>-->
            </div>
          </div>

          <!--Sidebar Side-->
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
export default {
  name: "HıfzıssıhhaSonuc",
  components: {
    Sidebar,
  },
  data() {
    return {
      Suanalizler: [],
      HıfzıssıhhaSonuc: [],
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Suanalizler");
    const suanalizmap = this.$store.getters.getSuanalizler;
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Suanalizler = Object.values(suanalizmap);
    this.Suanalizler.map(
      (item) => item.suanalizDurum === 3 && this.HıfzıssıhhaSonuc.push(item)
    );
    this.HıfzıssıhhaSonuc.sort(function(b, a) {
      return a.suanalizId - b.suanalizId;
    });
    this.loadkontrol = true;
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>

<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="welcome-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div
            v-for="(birim, index) in Birimler"
            :key="index"
            class="featured-block col-lg-4 col-md-6 col-sm-12"
          >
            <div class="inner-box">
              <div class="content-box">
                <div class="icon-box">
                  <img
                    :src="Belediyebilgiler.kapak"
                    alt
                    style="margin-bottom: 10px; padding-right: 15px"
                  />
                </div>

                <div class="content" style="margin-top: 20px">
                  <div class="subtitle">{{ Belediyebilgiler.belediyeadi }}</div>

                  <h4>
                    <a @click="detayagit(birim)">{{ birim.birimadi }}</a>
                  </h4>
                </div>
              </div>

              <div class="hover-box">
                <div
                  class="image-layer"
                  :style="{
                    backgroundImage: `url('${Belediyebilgiler.anaresim}')`,
                  }"
                ></div>

                <div class="inner">
                  <h4>
                    <a @click="detayagit(birim)">{{ birim.birimadi }}</a>
                  </h4>

                  <!-- <div v-html="birim.birimaciklamasi" class="text"></div> -->
                </div>
              </div>

              <div class="more-link">
                <a @click="detayagit(birim)">
                  <span class="flaticon-right-2"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Birimler",
  data() {
    return {
      Birimler: [],
      Belediyebilgiler: {},
      loadkont: false,
    };
  },
  methods: {
    detayagit(birim) {
      const birimIDD = birim.birimId;
      this.$router.push("/birimdetay/" + birimIDD);
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    const birimmap = this.$store.getters.getBirimler;
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Birimler = Object.values(birimmap);
    this.Birimler.sort(function (b, a) {
      return a.birimId - b.birimId;
    });
    this.loadkont = true;
  },
};
</script>
import firebase from 'firebase/app'
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

var firebaseConfig  = {
    apiKey: "AIzaSyB5dLyuIwzzYKSKV7RoupiVWZXRxMejVbU",
    authDomain: "demo1-e1dc2.firebaseapp.com",
    databaseURL: "https://demo1-e1dc2.firebaseio.com",
    projectId: "demo1-e1dc2",
    storageBucket: "demo1-e1dc2.appspot.com",
    messagingSenderId: "173118798767",
    appId: "1:173118798767:web:31edbc209ce86c1771cd5a",
    measurementId: "G-50ZXCS9ZTN"
}
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export{
    firebase,
    auth,
    db,
    storage
}

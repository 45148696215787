<template>
  <div>
    <div v-if="!loadkontrol" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Eski Başkanlar</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="auto-container">
        <div class="row">
          <div
            v-for="(uye, index) in Uyeler"
            :key="index"
            class="col-sm-6 col-md-3"
          >
            <div class="team-block">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <img
                      style="height: 300px; object-fit: cover"
                      :src="uye.kapak"
                      alt
                    />
                  </figure>

                  <div class="hover-box">
                    <div class="hover-inner">
                      <div class="hover-upper">
                        <h6>{{ uye.eskibaskanAdi }}</h6>
                      </div>

                      <div class="hover-lower">
                        <ul class="info">
                          <li>
                            <a>{{ uye.eskibaskanTarihi }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="lower-box">
                  <h4>
                    <a>{{ uye.eskibaskanAdi }}</a>
                  </h4>

                  <div class="designation">{{ uye.eskibaskanTarihi }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "EskiBaskanlar",
  data() {
    return {
      Uyeler: [],
      loadkontrol: false,
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "EskiBaskanlar");
    const eskbsk = this.$store.getters.getEskiBaskanlar;
    this.Uyeler = Object.values(eskbsk);
    this.Uyeler.sort(function(b, a) {
      return a.eskibaskanId - b.eskibaskanId;
    });
    this.loadkontrol = true;
  },
};
</script>

<template>
  <div>
    <section id="social-media">
      <div class="container-fluid">
        <div class="row row-cols-md-8">
          <div class="col">
            <p>
              <i class="fas fa-hand-point-right"></i>
              {{ Belediyebilgiler.belediyeadresi }}
            </p>
            <p>
              <i class="fas fa-hand-point-right"></i>
              {{ Belediyebilgiler.belediyeadi }} BELEDİYESİ
            </p>
            <p>
              <i class="fas fa-hand-point-right"></i> Telefon:
              {{ Belediyebilgiler.iletisim }}
            </p>
            <p>
              <i class="fas fa-hand-point-right"></i> Faks:
              {{ Belediyebilgiler.faxnumarasi }}
            </p>
            <p>
              <i class="fas fa-hand-point-right"></i>
              <a :href="{ mailto: Belediyebilgiler.mail }"
                >E-posta :{{ Belediyebilgiler.mail }}</a
              >
            </p>
            <p>
              <i class="fas fa-hand-point-right"></i> KEP Adresi:
              {{ Belediyebilgiler.kepAdresi }}
            </p>
          </div>
          <div class="col-md-4 m-4 mt-5">
            <br />
            <br />
            <router-link to="/">
              <img :src="Belediyebilgiler.kapak" alt="" id="resim" />
            </router-link>
          </div>
          <div class="col-md-3 m-4">
            <DatePick
              v-model="date"
              :hasInputElement="false"
              :months="[
                'Ocak',
                'Şubat',
                'Mart',
                'Nisan',
                'Mayıs',
                'Haziran',
                'Temmuz',
                'Ağustos',
                'Eylül',
                'Ekim',
                'Kasım',
                'Aralık',
              ]"
              :weekdays="['Pzt', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cts', 'Paz']"
            ></DatePick>
          </div>
        </div>
        <div class="triangle"></div>
      </div>
    </section>
  </div>
</template>
<script>
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
export default {
  name: "Footer",
  components: {
    DatePick,
  },
  data() {
    return {
      date: "",
      Belediyebilgiler: {},
      Baskanbilgisi: {},
    };
  },
  methods: {
    async dateFormat() {
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var year = d.getFullYear();

      this.date = `${year}-${month}-${day}`;
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    await this.baskancek();
    //await console.log(this.dateFormat())
  },
};
</script>
<style>
#footerMenu ul li a {
  color: #fff;
  text-decoration: none;
}
#footerMenu ul li a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;

  border-left: 15px solid #fff;

  padding-left: 10px;
  transition: all 0.4s ease;
}
#resim {
  /* width: 40%;
  height: 60%; */
  margin-left: 12%;
  background-size: contain;
}
</style>

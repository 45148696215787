var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"social-media"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row row-cols-md-8"},[_c('div',{staticClass:"col"},[_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_vm._v(" "+_vm._s(_vm.Belediyebilgiler.belediyeadresi)+" ")]),_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_vm._v(" "+_vm._s(_vm.Belediyebilgiler.belediyeadi)+" BELEDİYESİ ")]),_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_vm._v(" Telefon: "+_vm._s(_vm.Belediyebilgiler.iletisim)+" ")]),_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_vm._v(" Faks: "+_vm._s(_vm.Belediyebilgiler.faxnumarasi)+" ")]),_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_c('a',{attrs:{"href":{ mailto: _vm.Belediyebilgiler.mail }}},[_vm._v("E-posta :"+_vm._s(_vm.Belediyebilgiler.mail))])]),_c('p',[_c('i',{staticClass:"fas fa-hand-point-right"}),_vm._v(" KEP Adresi: "+_vm._s(_vm.Belediyebilgiler.kepAdresi)+" ")])]),_c('div',{staticClass:"col-md-4 m-4 mt-5"},[_c('br'),_c('br'),_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.Belediyebilgiler.kapak,"alt":"","id":"resim"}})])],1),_c('div',{staticClass:"col-md-3 m-4"},[_c('DatePick',{attrs:{"hasInputElement":false,"months":[
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık' ],"weekdays":['Pzt', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cts', 'Paz']},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]),_c('div',{staticClass:"triangle"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }
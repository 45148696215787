import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Haberdetay from '../views/Haberdetay.vue'
import IhaleDetay from '../views/IhaleDetay.vue'
import Duyurudetay from '../views/Duyurudetay.vue'
import Menuler from '../views/Menuler.vue'
import Baskanozgecmis from '../views/Baskanozgecmis.vue'
import BaskanMesaj from '../views/BaskanMesaj.vue'
import BaskanGaleri from '../views/Baskangaleri.vue'
import Birimler from '../views/Birimler.vue'
import BirimDetay from '../views/Birimdetay.vue'
import Meclisuyeleri from '../views/Meclisuyeleri.vue'
import Muhtarlar from '../views/Muhtarlar.vue'
import Mecliskararlari from '../views/MeclisKararlari.vue'
import Raporlar from '../views/FaaliyetRaporlari.vue'
import Raporlar2 from '../views/Denetimraporlari.vue'
import Yardimci from '../views/Yardimci.vue'
import Haberler from '../views/Haberler.vue'
import Ihaleler from '../views/Ihaleler.vue'
import SuAnalizSonuc from '../views/SuAnalizSonuc'
import KlorAramaSonuc from "../views/KlorAramaSonuc";
import HıfzıssıhhaSonuc from "../views/HıfzıssıhhaSonuc";

import Menulist from '../views/Menulist.vue'
import Duyurular from '../views/Duyurular.vue'
import Evlenenler from '../views/Evlenenler.vue'
import Vefatedenler from '../views/Vefatedenler.vue'
import Belgeornekleri from '../views/Belgeornekleri.vue'
import Galeri from '../views/Galeri.vue'
import Cralan from '../views/Cralan.vue'
import Sehitler from '../views/Sehitler.vue'
import Tarihce from '../views/Tarihce.vue'
import Kvkk from '../views/kvkk.vue'
import EtikKomisyonu from '../views/EtikKomisyonu.vue'
import Kentrehberi from '../views/Kentrehberi.vue'
import Yerdetay from '../views/Yerdetay.vue'
import EskiBaskanlar from '../views/EskiBaskanlar.vue'
import Encumenlarimiz from '../views/Encumenlarimiz.vue'
import Baskanlardetay from '../views/Baskanlardetay.vue'
import Ilgenel from '../views/Ilgenel.vue'
import Iletisim from '../views/Iletisim.vue'
import BaskanaSor from '../views/BaskanaSor.vue'
import Projeler from '../views/Projeler.vue'
import Tadilatlar from '../views/Tadilatlar.vue'
import Projedetay from '../views/Projedetay.vue'
import Tadilatdetay from '../views/Tadilatdetay.vue'
import KamanCevizi from '../views/KamanCevizi.vue'
import Ataturk from '../views/Ataturk.vue'
import Mevzuatlar from '../views/Mevzuatlar.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/birimler',
        name: 'Birimler',
        component: Birimler
    },
    {
        path: '/kamancevizi',
        name: 'KamanCevizi',
        component: KamanCevizi
    },
    {
        path: '/ataturk',
        name: 'Ataturk',
        component: Ataturk
    },
    {
        path: '/baskanasor',
        name: 'BaskanaSor',
        component: BaskanaSor
    },
    {
        path: '/iletisim',
        name: 'Iletisim',
        component: Iletisim
    },
    {
        path: '/eskibaskanlar',
        name: 'EskiBaskanlar',
        component: EskiBaskanlar
    },
    {
        path: '/encumenlarimiz',
        name: 'Encumenlar',
        component: Encumenlarimiz
    },
    {
        path: '/tarihce',
        name: 'Tarihce',
        component: Tarihce
    },
    {
        path: '/kvkk',
        name: 'Kvkk',
        component: Kvkk
    },
    {
        path: '/etikkomisyonu',
        name: 'EtikKomisyonu',
        component: EtikKomisyonu
    },
    {
        path: '/ilgenel',
        name: 'İlgenel',
        component: Ilgenel
    },
    {
        path: "/suanalizsonuc",
        name: "Suanalizsonuc",
        component: SuAnalizSonuc,
    },
    {
        path: "/kloraramasonuc",
        name: "Kloraramasonuc",
        component: KlorAramaSonuc,
    },
    {
        path: "/hıfzıssıhhasonuc",
        name: "Hıfzıssıhhasonuc",
        component: HıfzıssıhhaSonuc,
    },
    {
        path: '/kentrehberi',
        name: 'Yerler',
        component: Kentrehberi
    },
    {
        path: '/kentrehberidetay/:yerId',
        name: 'Yerdetay',
        component: Yerdetay
    },
    {
        path: '/baskanlarimizdetay/:baskanId',
        name: 'Baskanlardetay',
        component: Baskanlardetay
    },
    {
        path: '/galeri',
        name: 'galeri',
        component: Galeri
    },
    {
        path: '/cralan',
        name: 'Cralan',
        component: Cralan
    },
    {
        path: '/haberler',
        name: 'Haberler',
        component: Haberler
    },
    {
        path: '/ihaleler',
        name: 'Ihaleler',
        component: Ihaleler
    },
    {
        path: '/menulist',
        name: 'Menulist',
        component: Menulist
    },
    {
        path: '/projeler',
        name: 'projeler',
        component: Projeler
    },
    {
        path: '/tadilatlar',
        name: 'tadilatlar',
        component: Tadilatlar
    },
    {
        path: '/evlenenler',
        name: 'Evlenenler',
        component: Evlenenler
    },
    {
        path: '/sehitlerimiz',
        name: 'Sehitler',
        component: Sehitler
    },
    {
        path: '/vefat',
        name: 'Vefatedenler',
        component: Vefatedenler
    },
    {
        path: '/belgeler',
        name: 'Belgeler',
        component: Belgeornekleri
    },
    {
        path: '/duyuruilanlar',
        name: 'Duyurular',
        component: Duyurular
    },
    {
        path: '/menuler/:menuId',
        name: 'Menuler',
        component: Menuler
    },
    {
        path: '/duyurudetay/:duyuruilanId',
        name: 'duyuruilandetay',
        component: Duyurudetay
    },
    {
        path: '/yardimci',
        name: 'Yardımcı',
        component: Yardimci
    },
    {
        path: '/kararlar',
        name: 'Mecliskararlari',
        component: Mecliskararlari
    },
    {
        path: '/raporlar',
        name: 'FaaliyetRaporlari',
        component: Raporlar
    },
    {
        path: '/denetim',
        name: 'DenetimRaporlari',
        component: Raporlar2
    },
    {
        path: '/birimdetay/:birimId',
        name: 'Birimdetay',
        component: BirimDetay
    },
    {
        path: '/ozgecmis',
        name: 'Ozgecmis',
        component: Baskanozgecmis
    },
    {
        path: '/meclisuyeleri',
        name: 'Meclisuyeleri',
        component: Meclisuyeleri
    },
    {
        path: '/muhtarlar',
        name: 'Muhtarlar',
        component: Muhtarlar
    },
    {
        path: '/baskangaleri',
        name: 'Baskangaleri',
        component: BaskanGaleri
    },
    {
        path: '/mesaj',
        name: 'Mesaj',
        component: BaskanMesaj
    },
    {
        path: '/haberdetay/:haberId',
        name: 'Haberdetay',
        component: Haberdetay
    },
    {
        path: '/ihaledetay/:ihaleId',
        name: 'IhaleDetay',
        component: IhaleDetay
    },
    {
        path: '/projedetay/:ProjeId',
        name: 'Projedetay',
        component: Projedetay
    },
    {
        path: '/tadilatdetay/:tadilatId',
        name: 'Tadilatdetay',
        component: Tadilatdetay
    },
    {
        path: '/mevzuatlar',
        name: 'Mevzuatlar',
        component: Mevzuatlar
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    // {
    //     path: '/aktar',
    //     name: 'Aktar',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/Veri_Aktar/Aktar.vue')
    // }
]

const router = new VueRouter({
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
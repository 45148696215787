export default {
    silmekont: false,
    solkontrol: false,
    domainId: 'bos',
    domainName: '',

    /// Foto yükleme degiskenleri

    uploadtaskk: null,
    progres1: null,

    /// Veri çekme Değişkenleri

    BelediyeGenelBilgiler: {
        belediyeadi: '',
        belediyeadresi: '',
        faxnumarasi: '',
        ilceadi: '',
        iletisim: '',
        kapak: '',
        mail: '',
        websiteDailyCounter: null,
    },
    BaskanBilgileri: {
        baskanadi: '',
        baskanslogan: '',
        baskanface: '',
        baskantwit: '',
        baskaninsta: '',
        baskanprofil: '',
        ozgecmis: '',
        mesaj: '',
        Dosyalar: [],
    },
    IlceBilgiler: {
        tarihce: '',
        Dosyalar: []
    },
    Birimler: {},
    Uyeler: {},
    Muhtarlar: {},
    EskiBaskanlar: {},
    Encumenlar: {},
    Kararlar: {},
    Mevzuatlar:{},
    Ihale: {},
    Suanalizler: {},
    Cralanlar: {},
    Raporlar: {},
    Raporlar2: {},
    Projeler: {},
    Tadilatlar: {},
    Haberler: {},
    Sehitler: {},
    Slides: {},
    Yerler: {},
    Vefatlar: {},
    Evlenenler: {},
    Belgeler: {},
    DuyuruIlanlar: {},
    Menuler: {},
    menukontrol: false,
    belgekontrol: false,
    evlenenkontrol: false,
    vefatkontrol: false,
    yerkontrol: false,
    sehitkontrol: false,
    ilcekontrol: false,
    haberkontrol: false,
    projekontrol: false,
    tadilatkontrol: false,
    genelkontrol: false,
    baskankontrol: false,
    raporlarkontrol: false,
    raporlar2kontrol: false,
    birimlerkontrol: false,
    muhtarlarkontrol: false,
    eskibaskanlarkontrol: false,
    encumenlarkontrol: false,
    kararlarkontrol: false,
    mevzuatlarkontrol: false,
    ihalekontrol: false,
    cralanlarkontrol: false,
    uyelerkontrol: false,
    slideskontrol: false,
    suanalizkontrol: false,
}
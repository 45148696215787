<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section v-if="domAd == 'localhost'" class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>{{ Belediyebilgisi.belediyeadi }} Etik Komisyonu</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div
                    style="white-space: pre-line"
                    v-html="BaskanBilgisi.komisyon"
                  ></div>
                </div>

                <div class="share-post">
                  <strong
                    >{{ Belediyebilgisi.belediyeadi }} Sosyal Medya
                    Hesapları</strong
                  >

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a :href="Belediyebilgisi.ilceadi" target="_blank">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
export default {
  name: "Tarihce",
  data() {
    return {
      Belediyebilgisi: {},
      BaskanBilgisi: {},
      Ilce: {},
      loadkont: false,
    };
  },
  components: {
    Sidebar,
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Ilce");
    await this.$store.dispatch("vericekme", "Baskan");
    this.Belediyebilgisi = this.$store.getters.getBelgenel;
    this.Ilce = this.$store.getters.getIlce;
    this.BaskanBilgisi = this.$store.getters.getBaskan;
    this.loadkont = true;
    //console.log(this.Belediyebilgisi);
  },
};
</script>

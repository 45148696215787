import {
    db
} from "@/yardimcilar/firebaseinit";
import firebase from "firebase/app";
// import { auth } from '@/yardimcilar/firebaseinit';
// import { storage } from '@/yardimcilar/firebaseinit';
// import { firebase } from '@/yardimcilar/firebaseinit';

export const vericekme = async (context, col) => {
    var kontrol = false;
    switch (col) {
        case "Kararlar":
            kontrol = context.state.kararlarkontrol;
            break;
        case "Ihale":
            kontrol = context.state.ihalekontrol;
            break;
        case "Suanalizler":
            kontrol = context.state.suanalizkontrol;
            break;
        case "Cralanlar":
            kontrol = context.state.cralanlarkontrol;
            break;
        case "Muhtarlar":
            kontrol = context.state.muhtarlarkontrol;
            break;
        case "EskiBaskanlar":
            kontrol = context.state.eskibaskanlarkontrol;
            break;
        case "Encumenlar":
            kontrol = context.state.encumenlarkontrol;
            break;
        case "Uyeler":
            kontrol = context.state.uyelerkontrol;
            break;
        case "Birimler":
            kontrol = context.state.birimlerkontrol;
            break;
        case "Raporlar":
            kontrol = context.state.raporlarkontrol;
            break;
        case "Raporlar2":
            kontrol = context.state.raporlar2kontrol;
            break;
        case "Baskan":
            kontrol = context.state.baskankontrol;
            break;
        case "Genel":
            kontrol = context.state.genelkontrol;
            break;
        case "Projeler":
            kontrol = context.state.projekontrol;
            break;
        case "Tadilatlar":
            kontrol = context.state.tadilatkontrol;
            break;
        case "Haberler":
            kontrol = context.state.haberkontrol;
            break;
        case "Ilce":
            kontrol = context.state.ilcekontrol;
            break;
        case "Sehitler":
            kontrol = context.state.sehitkontrol;
            break;
        case "Yerler":
            kontrol = context.state.yerkontrol;
            break;
        case "Vefatlar":
            kontrol = context.state.vefatkontrol;
            break;
        case "Evlenenler":
            kontrol = context.state.evlenenkontrol;
            break;
        case "Belgeler":
            kontrol = context.state.belgekontrol;
            break;
        case "DuyuruIlanlar":
            kontrol = context.state.duyuruilankontrol;
            break;
        case "Menuler":
            kontrol = context.state.menukontrol;
            break;
        case "Slides":
            kontrol = context.state.slideskontrol;
            break;
        case "Mevzuatlar":
            kontrol = context.state.mevzuatlarkontrol;
            break;
        default:
            break;
    }
    if (kontrol == true) {
        //console.log("Veri Çekildi");
    } else {
        await db
            .collection(col)
            .doc(context.state.domainId)
            .get()
            .then((result) => {
                const gelendata = result.data();
                const data = gelendata[col];
                var mutdata = {
                    col: col,
                    data: data
                };
                context.commit("genelmutation", mutdata);
            })
            .catch((err) => {
                alert("vericekmehata" + err);
            });
    }
};
export const domainbelirle = async (context, domainAd) => {
    console.log(domainAd);
    //
    await firebase
        .firestore()
        .collection("Domainler")
        .where("domainismi", "==", domainAd)
        .get()
        .then((result) => {
            result.forEach((doc) => {
                const dat = doc.data();
                const domainId = dat.domainId;
                context.commit("domainmut", domainId);
            });
        })
        .catch((err) => {
            alert("domhata" + err);
        });
    // await db.collection('Domainler').where("domainismi","==","localhost").get()
    // .then((result) => {
    //   result.forE
    // }).catch((err) => {
    //   alert('domhata'+err);
    // });
};
<template>
  <div>
   
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Kaman Cevizi</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img src="/images/kaman-cevizi.jpg" alt />
                    </figure>
                  </div>

                  <h2>KAMAN CEVİZİ</h2>
                

                  <p style="white-space: pre-line">
                  Türkiye’de kabuklu meyve üretiminde önemli yere sahip olan KAMAN cevizinin meyvesi ve yaprakları sağlığa, kerestesi ekonomiye yarıyor. Meyvesi damar koruyucu, ishal kesici, mantar ve tümör tedavilerinde faydalı cevizin yaprağı da sindirim kolaylığı sağlıyor, iştah açıyor. Ceviz kerestesi mobilya sektöründe kıymetli kabul ediliyor, kökleri boya üretiminde kullanılıyor.
 
Türkiye’nin önemli tarım ürünleri arasında fındık, yer fıstığı ve Antep fıstığı gibi mamuller yanında ceviz de bulunuyor. 
 
Türkiye, ceviz genlerinin anavatanı kabul edilen ülkeler arasında yer alıyor.
Türkiye'de ceviz üretiminin % 90'ı Kaman'dan karşılanmaktadır. Ceviz ağaçları mobilya sektöründe önemli bir hammaddedir. Cevizden yapılan mobilyalar, dayanıklılığı ve kalitesiyle nesilden nesil'e aktarılıyor.
 
KAMAN cevizinin  damar koruyucu, ishal kesici, cildi temizleyici, siğil giderici, mantar hastalıkları, virüs enfeksiyonlarına karşı etkili olduğu ve tümör oluşmasını engellediği tıp çevreleri tarafından kabul ediliyor.
 
Kan dolaşımını düzenleyen ceviz, aynı zamanda, kan pıhtıları bozuyor, antialerjik  özellik gösteriliyor, karaciğer fonksiyonlarını düzenliyor, protein sentezini teşvik ediyor, bağışıklık fonksiyonlarının koruyor ve anormal antikor oluşumunu engelliyor. 
 
Tıp dünyasında son dönemde yapılan araştırmalar da, sert kabuklu meyvelerin kalp-damar hastalıklarının neden olduğu ölüm oranlarını azalttığı ve bu etkinin, yaş, egzersiz, sigara, alkol, diyetteki yağ oranı, lif, meyve, sebze ile ilişkili olduğu ortaya çıktı.

Cevizin yaprakları ve kökleri boya elde etmek için kullanılır. Özellikle el yapımı halılarda ceviz köklerinden elde edilen boyalar uzun yıllar solmadan kalabiliyor. Kurutulan bez torbalarda nem ve rutubet olmayan ortamlarda 2-3 Yıl saklanabilir.

Unutmadan Kaman'da her yıl adına festivaller düzenlenen ceviz festivali yapılmaktadır.Her Ekim ayının ilk cumartesi ve pazar günü düzenlenmektedir.
Geniş bir katılım olmaktadır.
                  </p>

                 
                </div>

              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
export default {
    components:{
        Sidebar
    }
}
</script>
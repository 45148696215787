<template>
  <div>
   
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Atatürk ve Kaman</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img src="/images/ata1.jpg" alt />
                    </figure>
                  </div>

                  <h2> Mustafa Kemal ve Temsil Heyeti Kaman'da (25 Aralık 1919)</h2>
                

                  <p style="white-space: pre-line">
                    Mustafa Kemal ve Temsil Heyeti bugün, yani 25 Aralık 1919 da Kaman’da. Bu nedenle 25 Aralık, Kaman tarihinde çok önemli bir gün. Kamanlılar için çok önemli bir gün. Çünkü Mustafa Kemal ve Arkadaşları kurtuluş yolunda, Kaman’da.

1914 – 1918 Birinci Dünya Savaşında yenik düşen Müttefiklerimizin yanında bizde yenik düşmüş sayıldık. Hemen fırsatı değerlendiren İtilaf devletleri yurdumuzu dört bir yandan sararak paylaşmaya başlarlar. Bu ne demekti? Bu işgal demekti, paylaşmak demekti, özgürlüğümüzün elimizden alınması demekti. İstanbul yönetimi her ne kadar taviz vererek yaşamını sürdürmeye çalışsa da, her geçen gün işgal kuvvetleri dişini biraz daha fazla gösteriyordu.

Bütün bu kuşatmanın sonunda 15 Mayıs 1919 da Yunanlıların İzmir’e asker çıkarmaları bardağı taşıran son damla olur. Gazeteci Hasan Tahsin’in ilk kurşunuyla direniş de başlamış oldu.

Yurdumuzun düşmanlar tarafından paylaşılmasına, nice ocakların sönmesine, yabancı bayrağının tepemizde dalgalanmasına razı olmayan halkımız, bu durumu onurlarına yediremeyip ellerinden gelen duaları edip beklemektedirler, "Ya Rabbim, bize bir sahip gönder!” diyerek.

Doğu bölgelerine ordu müfettişi olarak atanan Mustafa Kemal ve Arkadaşları 16 Mayısta İstanbul’dan ayrılıp, 19 Mayıs 1919 da Samsun’a ayak basarlar. İşte tam bu sırada, çoktandır kafasına koyduğu, yurdumuzu düşmandan kurtarmanın planını halkıyla paylaşma başlar. Samsun’dan Havza'ya geçerken Mustafa Kemal'in sesi duyulur tüm Anadolu’da, tüm dünyada; "Bizi öldürmek değil, diri diri mezara sokmak istiyorlar. Şimdi çukurun kenarındayız. Fakat hiçbir zaman ümit kesmeyeceğiz. Cesaretimiz bizi kurtaracaktır.!”

Mustafa Kemal ile doğan bu ışık, Türk Milleti'ne aradığı kurtuluş yolunu göstermiştir. Umutsuz insanlara umut veren bu ses tüm Anadolu'nun sesi olmuştur. O’nun cesareti ve yurt sevgisi Çanakkale'yi kurtarmamış mıydı? Şimdi sırada bütün vatanı kurtarmak vardı. Halk ona bağrını açtı. Bütün yüreklere su serpildi. Amasya’da – Erzurum’da - Sivas'ta M. Kemal halkıyla el eleydi. Yerel halkın oluşturduğu milli güç Kuva-i Milliye güçleri ve dernekler birleştiriliyor, halkı temsil edecek Heyet-i Temsiliye M. Kemal'in başkanlığında oluşturuluyordu. Milli güçler Heyet-i Temsiliye tarafından yönetilmeye başlanıyordu.

MUSTAFA Kemal ve Temsil Heyeti Ankara’ya gitmek üzere Sivas’tan hareket ederek Kayseri, Hacıbektaş, Mucur ve Nihayet Kırşehir’e gelmiştir.

Müdafa-i Hukuk Cemiyetinin Kaman temsilcisi Bektaşoğlu Ali çavuş, devamlı Kırşehir ile irtibat halindedir. Daha Mustafa Kemal’in Samsun’a çıktığı günden beri tüm gelişmeleri Kırşehir Müdafa-i Hukuk Cemiyetinden ve ileri gelenlerden devamlı haber alır. Ta o günlerden beri Kaman halkının birlik ve beraberliğini sağlarken yurdumuzun düşmanlar tarafından paylaşıldığını, kurtuluşun Mustafa Kemal ve Arkadaşlarının öncülüğünde halkımızın destekleriyle olacağını devamlı olarak gündüzleri pırtı dükkânında, geceleri ise köy odasında toplantılar yaparak Kaman halkına anlatır.

Kamanın ileri gelenlerini, oda sahiplerini, ata binen eli silah tutanlarını odasında toplayarak meşmeret ederler. Dışarıdan ve köylerden gelecek konukların hangi odalarda kalacakları, konukların atlarına iyi bakılması, oda sahipleri ve komşuların konukları için koyun, hindi ve tavuk keserek yemek hazırlamaları, her türlü rahatlarının sağlanması için ellerinden geldiğince yardımcı olmaları konuşulur. Fırsat buldukça da dükkânları, komşuları ve cami cemaatlerini ziyaret ederek hazırlıklar ve çalışmalar hakkında da bilgi alış verişinde bulunur.

Ali Çavuş, Mustafa Kemal ve temsil Heyetinin yemeklerini de hazırlamak için Kırşehirli Mehmet Efendilerin Ziya Beyin Hanımı Azime Hanımla kendi hanımı Şöhret Hanımı keyfanı olarak görevlendir. Keyfanı ve yardımcıları Ali Çavuşun kardeşi Bekir Ağanın evinde hazırlıklarına başlarlar.

Dünya var olalı tüm canlılar her sabah güneşin doğduğu tarafa bakarlar. Kimi güneşin ışığını, kimi yeni bir günün başlamasını, kimileri ise güneşle gelen mutluluğu paylaşmak için dönerler yönlerini doğuya, güneşin doğduğu yöne. Umut, güneşin doğduğu tarafta. 25 Aralık sabahı da Kaman Halkının gözü doğuya çevrilidir. Yüksek tepelerde hafifkar olmasına rağmen Baran Dağından doğacak güneşte o gün ayrı bir özellik var. Bir umut var. Bir beklenti var. Ta 19 Mayıstan beri bu umut ışığı hiç sönmemiş ki. O günden beri kara bulutlar hep dağılmış ülkemizin üzerinden. O günden beri Kaman halkı da bekler olmuş, bakar olmuşlar doğudan yana. Çünkü bu ışığın kaynağı her gün bir adım daha yaklaşıyor onlara doğru. İple çekiyorlar her günü. İçlerinden geçenleri kıyı, köşe, bucakta birbirleriyle paylaşıyorlar “Şu ışık gelse de bir görsek umudumuzu. Bir görebilsek bağımsız yaşamanın tadını tuzunu. Bu duyduğumuz masal kahramanı ya bir hayal ise, ya bir rüya ise, ya buraya uğramadan başka yerden giderse.”


25 Aralık 1919 Sabahı erken Kırşehir’den hareket eden Mustafa Kemal ve Temsil Heyetine Kırşehir atlıları da yol boyu arabaların etrafında cirit oynayarak eşlik ederler. Özbağ, Çiçekdağ, Cemele, Çuğun yol ayrımını geçen heyeti, Karanı Yazıda, Aydınlar (Sofular) İsahocalı ve Kurancılı atlıları karşılarlar. Arabalar hiç durmadan yollarına devam ederken iki gurubun atlıları tüm hünerleri ile cirit gösterisini sunarak arabaların iki yanında yarış yaparak Aydınlar Köyüne doğru yol alırlar. Aydınlar Köyünün küçük köy meydanındaki bakkalın önünde köyün imamı Mehmet Efendi ve köyün ileri gelen büyükleriyle diğer köylerden gelen konuklar köy halkı, kadın erkek, çoluk çocuk heyecanla karşılarlar Mustafa Kemal ve arkadaşlarını. Mustafa Kemal’i ve temsil heyetinin arabalarının etrafını saran köylüler, konuklarını görmek ve şöyle bir dokunmak için çabalayan kadın ve erkeklerin coşkusunu, yaşlılar durduramaz bir türlü. İmam Mehmet Efendinin Amin diyerek başlattığı dua ancak durdurabilir bu dalgalanmayı.

Dua sonrası kısa bir konuşma yapan imam Mehmet Efendi; “Hoş geldiniz Paşalarım, Aslanlarım. Hepimiz sizin emirlerinizdeyiz” der. Köy halkı hep birden bağırırlar. “Öl de ölelim, paşam” diye. Ortalığın sakinleşmesinden sonra; Mustafa Kemal, arabadan inmeden kısa ve öz konuşarak, düşmandan kurtulmanın müjdesini vererek yollarına devam ederler.

Kırşehir atlıları Kırşehir’e dönerlerken, üç köyün atlıları Samanlı Gediğinde bekleyen Kaman atlılarıyla buluşup, kısa bir cirit gösterisi sonunda nöbetlerini teslim etmenin gururu ile köylerine dönerler. 

Kaman atlılarının yanında ağırlaşan arabaların içindeki sarı saçlı, mavi gözlü umut kaynağı Mustafa Kemal’i tanıyan baş atlı arabaya yaklaşıp selamını verdikten sonra, tekmilini vererek, “Kaman’ın 100 Kuvay-ı Milliye atlıları emrinizdedir paşam.” Der.

Kuşluk vaktinin güneşine benzeyen gözleri ile atlıları süzerken göğsünü kabartıp birazda duygulu, elini kaldırıp selamı alırken; “Sağ olun aslanlarım. Ben ve arkadaşlarım sizlere güvenerek geldik buralara. Bir an evvel giderek işimize bakalım,”  diyerek yola devam işaretini verir.

Yel gibi eserek, ırmak gibi çağlayarak üç araba ve atlılar bir anda gelirler Darıözü’nde ki Şevket Çavuş’un Hanına. Arabalar hanın avlusuna çekilir. Konuklar hazırlanmış atlarına binerek hiç vakit kayıp etmeden Kaman atlılarının arasında Kocapınar’a doğru hareket ederler.

Kaman’ın doğu kuzeyinde ve üç kilometre kadar uzağında bulunan Kocapınar'da, Kaman Müdafa-i Hukuk Cemiyeti Reisi Bektaşoğlu Ali Çavuş, üyeler Çakıroğlu Musa Kahya, Kara Ömeroğlu Mehmet Efendi, Evişoğlu Hüseyin ve bucak halkı, atlısı, yayanı toplanırlar önceden. Konukları öyle sıradan konuklar değil, yarının Türkiye'sinin kurucularıdır.

Başta Bektaşoğlu Ali Çavuşun hareketleri hızlanır. Beraber bekledikleri Müdafayı Hukuk Cemiyetindeki arkadaşları ve Kamanın ileri gelenlerinin önünde bir ileri bir geri dolaşarak; “Değerli hemşerilerim. Paşamız gelince, ortalığı daraltmayalım. Ne söylerse iyi dinleyelim, sağ ol paşam, diyelim,” tembihlerini durmadan yineler.

Nihayet konuklar gelir ve ortalık karışır. Sarmaş dolaş, sevinç gözyaşlarıyla kısa süren karşılama sonrası Kocapınar’dan hareket eden heyet, şimdiki Yıldırım Sokaktan, Hamit Caddesinden, Efe Kazımın kahvesinin önünden, zahirecilerin bulunduğu Gazi Caddesinden ilerleyip Ömerhacılı Caddesi ile birleştiği yerdeki dükkânların arasında bulunan köy meydanına gelmeden atlarından inip yürüyerek, Kaman halkının arasına girerler Kaman'a yüzlerce atlı eşliğinde.

Umutları Baran Dağı kadar olan ve alev saçan gözleriyle halkının o saf ve tertemiz duygularını okurcasına kaya gibi duran Mustafa Kemal; “bu iş tamam, bu bilinçli ve kararlı halkla neler yapılmaz ki,” dercesine arkadaşlarına işaretini verir ve eli ile selamlamaya başlar dört bir yanı.

Kendisiyle gurur duyduğumuz, köyümüzün ileri geleni, Mudafa-i Hukuk Cemiyetinin köyümüzdeki Başkanı, Bektaşoğlu Ali çavuş, Safaların odasının güneyindeki binek taşının üstüne çıkarak gür sesiyle konuşmaya başlar;

“Kıymetli komşularım. Kıymetli köylülerim. Baylar, bayanlar. Çok şükür ki beklediğimiz büyük kurtarıcı, yiğit adam sağ selamet arkadaşlarıyla birlikte Kaman’ımıza geldiler. Bizlere onur verdiler. Gurur verdiler. Kendilerine sizin adınıza ve kendi adıma hoş geldiniz, sefalar getirdiniz Paşalarım diyorum. Kaman halkı, büyük küçük, kadın erkek hepimiz emrinizdeyiz. Yeter ki şu cennet vatanımızı düşmanın elinden bir kurtaralım.”  diyerek halkın sabrını daha fazla taşırmadan Mustafa Kemal’i buyur eder binek taşının üstüne. Kaman halkının, çığlıkları ve coşkuları sanki gökyüzünün bulutlarını delerler.  

Binek taşının üzerine çıkarak halkını izleyen Mustafa Kemal bir süre bekledikten sonra konuşmasına başlar.

“Sevgili Kamanlılar, Anadolu Halkı vefakâr ve cefakârdır. Biliyorsunuz yurdumuzun dört bir yanını düşmanlar sardı. Hatta paylaşmaya bile başladılar. Bu güzel yurdumuzun düşman çizmeleri altında çiğnenmesine hanginiz razı olursunuz. Hangimiz razı oluruz. Sizin bize verdiğiniz güçle yurdumuzu düşmanlardan kurtarıp refah içinde hep beraber yaşayacağız.” diye yaptığı konuşmayı; “olmayız Paşam, olamayız paşam, yanındayız ve emrindeyiz Paşam, öl de ölelim. Yeter ki vatanımız kurtulsun.” diye bağırmalarla keserler Paşamızın konuşmasını.

Halkın çığlıkları ve alkışları arasında Ali Çavuş Mustafa Kemal’in elinden tutarak indirir binek taşından. Tekrar taşın üzerine çıkar; “Değerli hemşerilerim. Biliyorsunuz konuklarımız uzun yoldan gelerek yoruldular. Şimdilik izin veriniz de biraz dinlensinler,” diyerek biraz ilerde hazırlanan atlara binerek Hacıpınar mevkiindeki odalarına doğru hareket ederler Mustafa Kemal ve Temsil heyeti. Tam bu ara yine ana baba gününe döner ortalık. Biraz yakından görebilmek, hatta dokunabilmek için yarış ederler birbirleriyle. Fakat hızla giden konukların yanına görevli atlılar kimseleri yaklaştırmazlar.

Ali Çavuşun konağında yemek yiyerek dinlenirlerken, Hamitli Rıza Bey gelerek bir süre konakta, bir süre de konağın kuzeyindeki ara sokakta yol güvenliği ve çalışmalar hakkında uzun uzun konuşurlar. Rıza Beyin ayrıldıktan sonra meraklı bakışlar arasında arkadaşlarının yanına gelirken mahallenin çocukları Mustafa Kemal’in etrafını sararlar. Ali Çavuş’un çocuklara kızmasına karşı gelerek; “dokunma çocuklara Ali Çavuş. Vatanı sizlerle kurtaracağız, geleceği de onlarla kuracağız,” diyerek Cumhuriyetin de müjdesini Kaman’da vermiş olur.   

Akşam yemeğinde yer sofrasının etrafını çeviren konuklar Mustafa Kemal’in başlamasını beklerler. Mustafa Kemal, her yerde olduğu gibi uzanarak hindinin kanadını koparıp tam ağzına götüreceği sırada, sofrada bulunanlar Paşalarını ilgiyle izlerler. Kısa bir süre sonra hayretle bakışlarını Rauf Paşa çevirirler. Bakışları anlayan Rauf Paşa çekinerek ve yutkunarak; “Paşam beyaz göğüs eti dururken neden hep kanatları yiyorsunuz?” Der. “Mustafa Kemal şöyle bir döner arkadaşına doğru, çok sert bakar, etrafı süzer, Rauf Paşaya dönerek; “uçacağız da, ondan paşa.“ Der. Bir süre sessizlik devam eder. Ortalık yatışınca iştahla yoğurt çorbası, kavurma, tavuklu pilav ve yoğurtlu balbaşı pekmez yenir. Yemekleri ve özellikle balbaşı pekmezi beğenen Mustafa Kemal yemekleri yapan hanımı tanımak ve teşekkür etmek amacıyla görmek ister.  Azime Hanım, “ben utanırım paşamdan,” diyerek evin dip taraflarına giderek saklanır. Çay ve kahveler içilerek edilen sohbetler sonrası odasına çekilen Mustafa kemal bir süre notlarını yazar.

Sabah erken kalkılır. Keyfanının hazırladığı yol azığı görevlilere çoktan verilmiştir. Hacıpınar Mahallesinde, biraz da yüksekte bulunan Ali Çavuşun konağının önüne çıkarak etrafı inceleyen Mustafa Kemal; “Köyünüz ne kadar güzel. Kim bilir yazın meyveleriniz ne kadar güzel olur,”  der.

Hazırlanan atlarına binen konukların yanında Ali Çavuş, konuklarını uğurlamaya gelen Kaman ileri gelenleri ve aynı kalabalık atlılarla evden ayrılarak Kocapınar’ın yolu tutulur. Şevket Çavuşun hanındaki arabalar daha önceden getirilerek hazırlanmıştır.

Atlarından inen Mustafa Kemal ve arkadaşları, başta Ali Çavuş olmak üzere uğurlamaya gelenlerle vedalaşarak arabalarına binerek Ankara’ya doğru ilerlerlerken cirit oynayan atlıların arasında el sallayarak gözden uzaklaşırlar.

Geride kalanlar Kaman’a dönerler gözyaşları ve kurtuluş umutlarıyla. Pek konuşmazlar, konuşamazlar bir türlü. Herkesin kafasında tek düşünce, tek umut kurtuluş…

Ali Çavuşun konağı dolar taşar konuklarla. Mustafa Kemal ve Arkadaşlarının gelişleri konuşulur, yorumlar yapılır ta o günden günümüze kadar.

Yaşar ŞAHİN                                                Mümtaz BOYACIOĞLU

Araştırmacı – Şair – Yazar                                       Araştırmacı – Şair – Yazar
                  </p>

                 
                </div>

              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
export default {
    components:{
        Sidebar
    }
}
</script>